import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link, useMediaQuery, useTheme } from "@mui/material";
import { Link as ScrollLink } from "react-scroll";
import {
  DISCORD_URL,
  FACEBOOK_URL,
  INSTAGRAM_URL,
  MENU_ITEMS,
  TWITTER_URL,
} from "../../utils/constants";
import {
  backgroundImageStyle,
  buttonSytle,
  footerStyle,
  linkStyle,
  menuAndSocialStyle,
  menuContainerStyle,
  overlayStyle,
  titleStyle,
} from "./styles";

export default function Footer() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box sx={footerStyle}>
      <Box sx={backgroundImageStyle}>
        <Box sx={overlayStyle}>
          <Typography
            variant="p"
            sx={{ ...titleStyle, fontSize: isSmallScreen ? "2em" : "3em" }}
          >
            Join The Cameleonz
          </Typography>
          <Button
            color="inherit"
            variant="outlined"
            size={isSmallScreen ? "medium" : "large"}
            sx={buttonSytle}
            onClick={() => (window.location.href = DISCORD_URL)}
          >
            JOIN US
          </Button>
        </Box>
      </Box>
      <Box sx={menuAndSocialStyle}>
        <Box>
          <IconButton
            sx={{ color: "white" }}
            href={FACEBOOK_URL}
            target="_blank"
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            sx={{ color: "white" }}
            href={TWITTER_URL}
            target="_blank"
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            sx={{ color: "white" }}
            href={INSTAGRAM_URL}
            target="_blank"
          >
            <InstagramIcon />
          </IconButton>
        </Box>
        <Box sx={menuContainerStyle}>
          {MENU_ITEMS.map((item, index) => (
            <Typography sx={linkStyle} key={index}>
              <ScrollLink
                to={item.toLowerCase()}
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={isSmallScreen ? -76 : -84}
              >
                {item}
              </ScrollLink>
            </Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
}