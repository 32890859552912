import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Carousel from "../Carousel";
import LitePaperModal from "../LitePaperModal";
import { DISCORD_URL } from "../../utils/constants";

const styles = {
  rootBox: {
    backgroundColor: "black",
    minHeight: "calc(100vh - 84px)",
    overflow: "unset",
    display: "flex",
    justifyContent: "center",
  },
  innerBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
  },
  textBox: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  title: {
    textTransform: "capitalize",
    color: "white",
    lineHeight: "unset",
    fontFamily: "'Sora',sans-serif",
  },
  paragraph: {
    fontSize: "1.25em",
    color: "white",
    fontWeight: 400,
    fontFamily: "'Sora',sans-serif",
  },
  secondaryParagraph: {
    fontSize: "1em",
    color: "rgba(255, 255, 255, 0.6)",
    fontWeight: 400,
    fontFamily: "'Sora',sans-serif",
  },
  buttonGroup: {
    display: "flex",
    gap: "10px",
  },
  discordButton: {
    background: "linear-gradient(45deg, #8049F9, #FE2552)",
    border: "unset",
    color: "white",
    fontFamily: "'Sora',sans-serif",
    "&:hover": {
      border: "2px solid #8049F9",
      color: "#8049F9",
      background: "unset",
    },
  },
  litePaperButton: {
    border: "2px solid #8049F9",
    color: "#8049F9",
    fontFamily: "'Sora',sans-serif",
    "&:hover": {
      color: "white",
      background: "linear-gradient(45deg, #8049F9, #FE2552)",
      border: "unset",
    },
  },
};

const About = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box
      id="about"
      sx={{
        ...styles.rootBox,
        paddingY: isSmallScreen ? "50px" : "40px",
        paddingX: isSmallScreen ? "20px" : "40px",
      }}
    >
      <Box
        sx={{
          ...styles.innerBox,
          flexDirection: isMobile ? "column" : "row",
          width: isSmallScreen ? "95%" : "80%",
          gap: isSmallScreen ? "3em" : "5em",
        }}
      >
        <Carousel />
        <Box
          sx={{
            ...styles.textBox,
            alignItems: isMobile ? "center" : "flex-start",
          }}
        >
          <Typography
            component="h2"
            sx={{
              ...styles.title,
              fontSize: isSmallScreen ? "2.5em" : "3em",
              textAlign: isMobile ? "center" : "start",
            }}
          >
            Welcome To Cameleonz.
          </Typography>
          <Typography
            component="p"
            sx={{
              ...styles.paragraph,
              textAlign: isMobile ? "center" : "start",
              fontSize: isSmallScreen ? "1em" : "1.25em",
            }}
          >
            Explore the milestones and future plans of Camelonz as we journey
            through the multiverse to revive and evolve these mystical
            creatures. Join us as we unlock the secrets of the Great Cataclysm
            and restore balance to the universe.
          </Typography>
          <Typography
            component="p"
            sx={{
              ...styles.secondaryParagraph,
              textAlign: isMobile ? "center" : "start",
              fontSize: isSmallScreen ? "0.85em" : "1em",
            }}
          >
            Breeding is essential in Camelonz, allowing players to create new
            generations with unique traits. Select pairs carefully to influence
            offspring traits, aiming for the rarest and most powerful Camelonz.
            Manage genetic risks and participate in special events to unlock
            exclusive traits and build a lasting legacy.
          </Typography>
          <Box sx={styles.buttonGroup}>
            <Button
              color="inherit"
              variant="outlined"
              size={isSmallScreen ? "small" : "large"}
              sx={styles.discordButton}
              onClick={() => (window.location.href = DISCORD_URL)}
            >
              JOIN OUR DISCORD
            </Button>
            <Button
              color="inherit"
              variant="outlined"
              size={isSmallScreen ? "small" : "large"}
              sx={styles.litePaperButton}
              onClick={handleOpen}
            >
              VIEW LITE PAPER
            </Button>
          </Box>
        </Box>
      </Box>
      <LitePaperModal open={open} handleClose={handleClose} />
    </Box>
  );
};

export default About;
