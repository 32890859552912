import React, { useState, useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import styles from "./styles.module.scss";

const CountDownTimer = () => {
  const targetDate = +new Date("2024-08-02T10:00:00");
  const [isExpired, setIsExpired] = useState(false);

  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = targetDate - now;

    if (difference <= 0) {
      setIsExpired(true);
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  return (
    <Box className={styles.container}>
      <Typography variant="h6" color="white" className={styles.title}>
        🔥 Public Sale Countdown
      </Typography>
      <Grid
        container
        spacing={1}
        justifyContent="center"
        mt={2}
        className={styles.grid}
      >
        {["days", "hours", "minutes", "seconds"].map((unit, index) => (
          <Grid item key={index} className={styles.gridItem}>
            <Box className={styles.gridItemContent}>
              <Typography
                variant="h6"
                color={isExpired ? "white" : "white"}
                className={styles.itemTitle}
              >
                {timeLeft[unit]}
              </Typography>
              <Typography
                variant="caption"
                color={isExpired ? "white" : "white"}
                className={styles.itemValue}
              >
                {unit.charAt(0).toUpperCase() + unit.slice(1)}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CountDownTimer;
