import React, { useState, useEffect } from 'react';
import { Button, Typography } from '@mui/material';

const ConnectBearby = ({ onConnected }) => {
  const [massaAddress, setMassaAddress] = useState('');
  const [bearbyDetected, setBearbyDetected] = useState(false);

  useEffect(() => {
    const checkBearby = () => {
      if (typeof window.bearby !== 'undefined') {
        setBearbyDetected(true);
      } else {
        setBearbyDetected(false);
      }
    };

    checkBearby();

    window.addEventListener('bearby#initialized', checkBearby);

    return () => {
      window.removeEventListener('bearby#initialized', checkBearby);
    };
  }, []);

  const connectMassaWallet = async () => {
    if (bearbyDetected) {
      try {
        const accounts = await window.bearby.request({ method: 'massa_requestAccounts' });
        if (accounts && accounts.length > 0) {
          const address = accounts[0];
          setMassaAddress(address);
          onConnected(address);
        }
      } catch (error) {
        console.error('Failed to connect Massa wallet:', error);
      }
    } else {
      alert('Please install Bearby to connect your Massa wallet.');
    }
  };

  return (
    <div>
      {bearbyDetected ? (
        <Button onClick={connectMassaWallet}>
          {massaAddress ? massaAddress : 'Connect Bearby Wallet'}
        </Button>
      ) : (
        <Typography>Please install Bearby to connect your Massa wallet.</Typography>
      )}
    </div>
  );
};

export default ConnectBearby;
