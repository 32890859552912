import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  .neumorphism-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    border-radius: 50px;
    background: #e0e0e0;
    box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.8), 5px 5px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s ease;
    cursor: pointer;
    border: none;
    outline: none;
    margin-top: 1rem;

    &:hover {
      box-shadow: -1px -1px 5px rgba(255, 255, 255, 0.6), 1px 1px 5px rgba(0, 0, 0, 0.3), inset -2px -2px 5px rgba(255, 255, 255, 1), inset 2px 2px 4px rgba(0, 0, 0, 0.3);
      color: grey;
    }

    .text {
      font-size: 1rem;
    }
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;

  input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    font-size: 1rem;
    margin-right: 0.5rem;
  }
`;

const ConnectEthereum = ({ onConnected }) => {
  const [ethereumAddress, setEthereumAddress] = useState('');
  const [inputAddress, setInputAddress] = useState('');
  const [isMetaMaskInstalled, setIsMetaMaskInstalled] = useState(true);

  useEffect(() => {
    const checkEthereumAddress = async () => {
      if (typeof window.ethereum !== 'undefined') {
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        if (accounts.length > 0) {
          const address = accounts[0];
          setEthereumAddress(address);
          onConnected(address);
        }
      } else {
        setIsMetaMaskInstalled(false);
      }
    };

    checkEthereumAddress();

    const handleAccountsChanged = (accounts) => {
      if (accounts.length > 0) {
        const address = accounts[0];
        setEthereumAddress(address);
        onConnected(address);
      } else {
        setEthereumAddress('');
      }
    };

    if (typeof window.ethereum !== 'undefined') {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
    }

    return () => {
      if (typeof window.ethereum !== 'undefined') {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
      }
    };
  }, [onConnected]);

  const connectEthereumWallet = async () => {
    if (inputAddress) {
      setEthereumAddress(inputAddress);
      onConnected(inputAddress);
    } else if (typeof window.ethereum !== 'undefined') {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        const address = accounts[0];
        setEthereumAddress(address);
        onConnected(address);
      } catch (error) {
        console.error('Failed to connect Ethereum wallet:', error);
      }
    } else {
      alert('Please install MetaMask to connect your wallet.');
    }
  };

  const handleInputChange = (e) => {
    setInputAddress(e.target.value);
  };

  return (
    <Container>
      <InputWrapper>
        <input
          type="text"
          placeholder="Paste your Ethereum address"
          value={inputAddress}
          onChange={handleInputChange}
        />
      </InputWrapper>
      <ButtonWrapper>
        <button className="neumorphism-button" onClick={connectEthereumWallet}>
          <span className="text">{ethereumAddress || 'Connect Ethereum Wallet'}</span>
        </button>
      </ButtonWrapper>
    </Container>
  );
};

export default ConnectEthereum;
