export const MENU_ITEMS = ["HOME", "ABOUT", "ROADMAP", "FAQ"];

export const DISCORD_URL = "https://discord.gg/2MmQEhY22D";
export const FACEBOOK_URL = "https://www.facebook.com/cameleoNFT";
export const TWITTER_URL = "https://x.com/CameleoNFT";
export const INSTAGRAM_URL = "https://www.instagram.com/cameleonft/";

export const LITE_PAPER_DOC = "/Documents/LitePaper.pdf";
export const ROADMAP_DOC = "/Documents/ROADMAP.pdf";
export const WORKER_URL = `https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js`;

export const ROADMAP_DATA = [
  {
    date: "7th July 2024",
    title: "Whitelist",
    description:
      "Early supporters and community members gain exclusive access to mint Camelonz NFTs before the public sale. Secure your spot and join the adventure early!",
  },
  {
    date: "10th July 2024",
    title: "Airdrop Hunt",
    description:
      "Participate in our exciting Airdrop Hunt! Complete quests, earn points, and stand a chance to win exclusive Camelonz NFTs and other exciting rewards.",
  },
  {
    date: "2nd August 2024",
    title: "Public Sale on Launchpad",
    description:
      "The official launchpad for Camelonz NFTs. Join the public sale to mint your unique Camelonz and begin your journey in the multiverse.",
  },
  {
    date: "20th September 2024",
    title: "Breeding Game Launch",
    description:
      "Experience the full potential of Camelonz with the launch of our breeding game. Breed your Camelonz, discover new traits, and build powerful lineages.",
  },
];

export const FAQ_DATA = [
  {
    question: "What makes Cameleon NFTs unique in the NFT space?",
    answer:
      "Cameleon NFTs are 'evolvable,' meaning they can change over time. Unlike traditional static NFTs, our NFTs can upgrade their features and capabilities autonomously, offering a more dynamic and engaging experience for collectors.",
  },
  {
    question: "How do these NFTs actually 'evolve'?",
    answer:
      "The evolution process is powered by smart contracts on the Massa Network. NFTs can upgrade their traits and abilities based on various factors such as specific events (like Halloween, Bitcoin halving,...) or other predefined triggers within the ecosystem.",
  },
  {
    question: "Why did you choose to build on the Massa Network?",
    answer:
      "Massa Network offers unique features like autonomous smart contracts that align perfectly with our vision for evolvable NFTs. Its infrastructure allows for seamless and efficient evolution of our NFTs, setting a new standard in NFT technology.",
  },
  {
    question:
      "How does the revenue-sharing model work for Cameleonz NFT holders?",
    answer:
      "Holders of Cameleonz NFTs will receive a share of the transaction fees generated on the Cameleon Marketplace. The distribution formula favors holders of rare items and NFTs, incentivizing long-term holding and community engagement.",
  },
  {
    question: "What's the total supply of Cameleonz NFTs?",
    answer:
      "The total supply is 1,050 NFTs, divided into different rarity tiers: 500 Common, 250 Uncommon, 150 Rare, 100 Epic, and 50 Mythical (hand-crafted).",
  },
  {
    question: "How can people participate in the Cameleonz NFT launch?",
    answer:
      "There will be a pre-sale on July 8th, followed by a public sale on July 28th. Details on how to participate will be available on our website and social media channels.",
  },
  {
    question:
      "You mentioned a bridge for minting NFTs. Can you explain how this works?",
    answer:
      "We've developed a revolutionary bridge that allows users to mint NFTs using stablecoins, as well as cryptocurrencies from Polygon, BSC, and Ethereum. This makes our NFTs more accessible to a wider audience during minting.",
  },
  {
    question:
      "Are there any plans to expand the Cameleon ecosystem in the future?",
    answer:
      "Yes, we're planning to develop a breeding game using these evolvable NFTs. We also have plans for a second collection onboarding in August, details of which will be announced soon.",
  },
];
