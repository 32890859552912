import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { MENU_ITEMS } from "../../utils/constants";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import TimelineIcon from "@mui/icons-material/Timeline";
import HelpIcon from "@mui/icons-material/Help";
import {
  appBarStyle,
  buttonStyle,
  menuContainerStyle,
  menuItemStyles,
  drawerStyle,
  listItemHoverStyle,
} from "./styles";

const Header = ({ onConnectClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const getIcon = (item) => {
    switch (item.toLowerCase()) {
      case "home":
        return <HomeIcon sx={{ color: "white" }} />;
      case "about":
        return <InfoIcon sx={{ color: "white" }} />;
      case "roadmap":
        return <TimelineIcon sx={{ color: "white" }} />;
      case "faq":
        return <HelpIcon sx={{ color: "white" }} />;
      default:
        return null;
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{ ...appBarStyle, zIndex: theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <img src="../../logo.svg" alt="Logo" style={{ height: "30px" }} />
        </Typography>
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={handleDrawerClose}
              disableScrollLock={true}
              PaperProps={{
                sx: {
                  ...drawerStyle,
                  width: isMobile ? "100vw" : "auto",
                  marginTop: isSmallScreen ? "76px" : "84px",
                },
              }}
            >
              <Box
                sx={{
                  width: isMobile ? "100vw" : "auto",
                  height: isSmallScreen
                    ? "calc(100vh - 76px)"
                    : "calc(100vh - 84px)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <List>
                  {MENU_ITEMS.map((item) => (
                    <ScrollLink
                      to={item.toLowerCase()}
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={isSmallScreen ? -76 : -84}
                      key={item}
                    >
                      <ListItem
                        button
                        onClick={handleDrawerClose}
                        sx={listItemHoverStyle}
                      >
                        <ListItemIcon>{getIcon(item)}</ListItemIcon>
                        <ListItemText
                          primary={item}
                          sx={{
                            color: "white",
                            fontFamily: "'Sora',sans-serif!important",
                          }}
                        />
                      </ListItem>
                    </ScrollLink>
                  ))}
                </List>
                {/* <Button
                onClick={onConnectClick}
                  color="inherit"
                  variant="outlined"
                  size="large"
                  sx={{
                    ...buttonStyle,
                    width: isSmallScreen ? "80%" : "60%",
                    margin: "0 auto",
                    marginBottom: "1.5em",
                  }}
                >
                  Connect
                </Button> */}
              </Box>
            </Drawer>
          </>
        ) : (
          <Box sx={menuContainerStyle}>
            {MENU_ITEMS.map((item) => (
              <Button key={item} color="inherit" sx={menuItemStyles}>
                <ScrollLink
                  to={item.toLowerCase()}
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-70}
                >
                  {item}
                </ScrollLink>
              </Button>
            ))}
            {/* <Button color="inherit" variant="outlined" sx={buttonStyle} onClick={onConnectClick} >
              Connect
            </Button> */}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
