import React from "react";
import styled from "styled-components";
import GIF from "../assets/Nfts/gif.gif"; 

const GifContainer = styled.div`
  width: 100%;
  background: transparent;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 64em) {
    min-width: 40vh;
  }
`;

const CoverGif = () => {
  return (
    <GifContainer>
      <img src={GIF} alt="Animation" />
    </GifContainer>
  );
};

export default CoverGif;
