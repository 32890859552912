import React from "react";
import { Box, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FAQ_DATA } from "../../utils/constants";

const styles = {
  rootBox: {
    backgroundColor: "black",
    minHeight: "calc(100vh - 84px)",
    overflow: "unset",
    display: "flex",
    justifyContent: "center",
  },
  innerBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
    gap: "2em",
    width: "80%",
  },
  title: {
    fontSize: "3em",
    textTransform: "capitalize",
    color: "white",
    lineHeight: "unset",
    fontFamily: "'Sora',sans-serif",
  },
  faqBox: {
    display: "flex",
    gap: "2rem",
    paddingY: "20px",
  },
  accordionGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  accordionSummary: {
    color: "rgba(255, 255, 255, 0.6)",
    background: "#1a1928",
    lineHeight: "1.5rem",
    fontFamily: "'Sora',sans-serif",
  },
  accordionDetails: {
    color: "rgba(255, 255, 255, 0.6)",
    background: "#1a1928",
    fontSize: "0.85rem",
    lineHeight: "1.5rem",
    fontFamily: "'Sora',sans-serif",
  },
  expandIcon: {
    color: "rgba(255, 255, 255, 0.6)",
  },
};

const Faq = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const halfLength = Math.ceil(FAQ_DATA.length / 2);
  const firstHalf = FAQ_DATA.slice(0, halfLength);
  const secondHalf = FAQ_DATA.slice(halfLength);

  return (
    <Box
      id="faq"
      sx={{ ...styles.rootBox, padding: isSmallScreen ? "20px" : "40px" }}
    >
      <Box sx={styles.innerBox}>
        <Typography component="h2" sx={styles.title}>
          FAQ
        </Typography>
        <Box
          sx={{ ...styles.faqBox, flexDirection: isMobile ? "column" : "row" }}
        >
          <Box sx={styles.accordionGroup}>
            {firstHalf.map((faq, index) => (
              <Paper elevation={6} key={index}>
                <Accordion key={`first-half-${index}`}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={styles.expandIcon} />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                    sx={styles.accordionSummary}
                  >
                    {faq.question}
                  </AccordionSummary>
                  <AccordionDetails sx={styles.accordionDetails}>
                    {faq.answer}
                  </AccordionDetails>
                </Accordion>
              </Paper>
            ))}
          </Box>
          <Box sx={styles.accordionGroup}>
            {secondHalf.map((faq, index) => (
              <Paper elevation={6} key={index}>
                <Accordion key={`second-half-${index}`}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={styles.expandIcon} />}
                    aria-controls={`panel${index + halfLength}-content`}
                    id={`panel${index + halfLength}-header`}
                    sx={styles.accordionSummary}
                  >
                    {faq.question}
                  </AccordionSummary>
                  <AccordionDetails sx={styles.accordionDetails}>
                    {faq.answer}
                  </AccordionDetails>
                </Accordion>
              </Paper>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Faq;
