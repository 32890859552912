import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import { ROADMAP_DATA } from "../utils/constants";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const CustomTimelineItem = styled(TimelineItem)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    "&:before": {
      display: "none",
    },
  },
}));

const timelineStyles = {
  oppositeContent: {
    margin: "auto 0",
    color: "white",
    fontFamily: "'Sora',sans-serif",
  },
  content: {
    py: { xs: "8px", md: "12px" },
    px: { xs: 1, md: 2 },
  },
  dot: {
    bgcolor: "white",
  },
  title: {
    color: "white",
    fontSize: { xs: "1rem", md: "1.25rem" },
    fontFamily: "'Sora',sans-serif",
  },
  description: {
    color: "rgba(255, 255, 255, 0.6)",
    fontSize: { xs: "0.875rem", md: "1rem" },
    fontFamily: "'Sora',sans-serif",
  },
};

const animationVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

export default function CustomizedTimeline() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { ref, inView } = useInView({
    triggerOnce: false, // Only trigger animation once
    threshold: 0.1, // Trigger when 10% of the component is visible
  });

  return (
    <Timeline
      position={isSmallScreen ? "right" : "alternate"}
      sx={{ height: "100%" }}
      ref={ref}
    >
      {ROADMAP_DATA.map((item, index) => (
        <CustomTimelineItem key={index}>
          {!isSmallScreen && (
            <TimelineOppositeContent sx={timelineStyles.oppositeContent}>
              {item.date}
            </TimelineOppositeContent>
          )}
          <TimelineSeparator>
            <TimelineConnector sx={timelineStyles.dot} />
            <TimelineDot sx={{ color: "#8049F9" }} />
            <TimelineConnector sx={timelineStyles.dot} />
          </TimelineSeparator>
          <TimelineContent
            component={motion.div}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={animationVariants}
            transition={{ duration: 0.5, delay: index * 0.2 }}
            sx={timelineStyles.content}
          >
            <Typography variant="h6" component="span" sx={timelineStyles.title}>
              {item.title}
            </Typography>
            <Typography sx={timelineStyles.description}>
              {item.description}
            </Typography>
            {isSmallScreen && (
              <Typography variant="body2" sx={timelineStyles.oppositeContent}>
                {item.date}
              </Typography>
            )}
          </TimelineContent>
        </CustomTimelineItem>
      ))}
    </Timeline>
  );
}
