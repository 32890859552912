import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CustomizedTimeline from "../Timeline";
import RoadmapModal from "../RoadmapModal";

const styles = {
  container: {
    backgroundColor: "#1a1928",
    minHeight: `calc(100vh - 84px)`,
    overflow: "unset",
    display: "flex",
    justifyContent: "center",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1a1928",
    gap: "2em",
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px",
  },
  title: {
    fontSize: "3em",
    textTransform: "capitalize",
    color: "white",
    lineHeight: "unset",
    fontFamily: "'Sora',sans-serif"
  },
  button: {
    background: "linear-gradient(45deg, #8049F9, #FE2552)",
    border: "unset",
    color: "white",
    borderRadius: "50px",
    fontWeight: 500,
    fontFamily: "'Sora',sans-serif",
    "&:hover": {
      border: "2px solid #8049F9",
      color: "#8049F9",
      background: "unset",
    },
  },
};

const Roadmap = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box
      id="roadmap"
      sx={{ ...styles.container, padding: isSmallScreen ? "20px" : "40px" }}
    >
      <Box
        sx={{ ...styles.innerContainer, width: isSmallScreen ? "100%" : "80%" }}
      >
        <Box sx={styles.header}>
          <Typography component="h2" sx={styles.title}>
            Roadmap
          </Typography>
          <Button
            color="inherit"
            variant="outlined"
            size="small"
            sx={styles.button}
            onClick={handleOpen}
          >
            VIEW ROADMAP
          </Button>
        </Box>
        <CustomizedTimeline />
      </Box>
      <RoadmapModal open={open} handleClose={handleClose} />
    </Box>
  );
};

export default Roadmap;