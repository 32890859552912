export const appBarStyle = {
  backgroundColor: "#1a1928",
  paddingX: "20px",
  paddingY: "10px",
};
export const buttonStyle = {
  marginLeft: 2,
  background: "linear-gradient(45deg, #8049F9, #FE2552)",
  border: "unset",
  fontFamily: "'Sora',sans-serif",
  "&:hover": {
    border: "2px solid #8049F9",
    color: "#8049F9",
    background: "unset",
  },
};

export const menuItemStyles = {
  fontFamily: "'Sora',sans-serif",
  "&:hover": {
    color: "#8049F9",
    borderBottom: "2px solid #8049F9",
    backgroundColor: "unset",
    borderRadius: "unset",
  },
};

export const menuContainerStyle = {
  display: "flex",
  gap: 2,
};
export const drawerStyle = {
  backgroundColor: "#1a1928",
  color: "white",
};

export const listItemHoverStyle = {
  borderBottom: "1px solid #8080804a",
  fontFamily: "'Sora',sans-serif",
  "&:hover": {
    backgroundColor: "#8049F9",
  },
};
