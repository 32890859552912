export const footerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#1a1928",
  color: "white",
};
export const backgroundImageStyle = {
  position: "relative",
  width: "100%",
  height: "300px",
  backgroundImage: 'url("banner.jpg")',
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  marginBottom: "20px",
  gap: "20px",
};
export const overlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(26, 25, 40, 0.7)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "20px",
};
export const menuAndSocialStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  maxWidth: "800px",
  gap: "1rem",
  padding: "20px",
};
export const linkStyle = {
  color: "white",
  textDecoration: "none",
  fontFamily: "'Sora',sans-serif",
  "&:hover": {
    color: "#8049F9",
    textDecoration: "underline",
  },
};
export const buttonSytle = {
  fontSize: "1.3em",
  background: "linear-gradient(45deg, #8049F9, #FE2552)",
  border: "unset",
  color: "white",
  fontFamily: "'Sora',sans-serif",
  "&:hover": {
    fontSize: "1.5em",
  },
};

export const titleStyle = {
  textTransform: "capitalize",
  fontSize: "3em",
  fontWeight: 700,
  textAlign: "center",
  fontFamily: "'Sora',sans-serif",
};
export const menuContainerStyle = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  gap: "1rem",
};
