import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { ROADMAP_DOC, WORKER_URL } from "../utils/constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: "800px",
  height: "80%",
  maxHeight: "80%",
  bgcolor: "#1a1928",
  color: "white",
  border: "unset",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
};
const overlayStyle = {
  zIndex: 1500,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
};
const iconStyle = {
  position: "absolute",
  top: 8,
  right: 8,
  color: "white",
};
const contentStyle = {
  height: "100%",
  overflow: "auto",
  margin: "16px auto",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const RoadmapModal = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={overlayStyle}
    >
      <Box sx={style}>
        <IconButton onClick={handleClose} sx={iconStyle}>
          <CloseIcon />
        </IconButton>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            textAlign: "center",
            fontFamily: "'Sora',sans-serif",
            fontSize: "2em",
          }}
        >
          ROADMAP
        </Typography>
        <div style={contentStyle}>
          <Worker workerUrl={WORKER_URL}>
            <Viewer fileUrl={ROADMAP_DOC} />
          </Worker>
        </div>
      </Box>
    </Modal>
  );
};

export default RoadmapModal;
