import React, { useState } from 'react';
import { Modal, Box, Typography, Stepper, Step, StepLabel, Button } from '@mui/material';
import { styled } from '@mui/system';
import ConnectEthereum from './ConnectEthereum';
import ConnectBearby from './ConnectBearby';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DoneIcon from '@mui/icons-material/DoneAll';

const steps = ['Connect with ETHEREUM Wallet', 'Connect with MASSA Wallet', 'Mint'];

const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
  '& .MuiStepLabel-label': {
    fontSize: '1.2rem',
    color: '#333',
    fontWeight: '600',
    fontFamily: 'Arial, sans-serif',
  },
  '& .Mui-active .MuiStepLabel-label': {
    color: '#8bc34a', 
  },
  '& .Mui-completed .MuiStepLabel-label': {
    color: '#8bc34a', 
  },
}));

const ConnectModal = ({ open, onClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [connectedAddress, setConnectedAddress] = useState('');
  const [isStepComplete, setIsStepComplete] = useState([false, false, false]); 

  const handleNext = () => {
    setIsStepComplete((prev) => {
      const newSteps = [...prev];
      newSteps[activeStep] = true;
      return newSteps;
    });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setConnectedAddress('');
    setIsStepComplete([false, false, false]);
  };

  const handleConnected = (address) => {
    setConnectedAddress(address);
    setIsStepComplete((prev) => {
      const newSteps = [...prev];
      newSteps[activeStep] = true;
      return newSteps;
    });
  };

  const StepIcon = ({ icon }) => {
    const icons = {
      1: <AccountBalanceWalletIcon style={{ fontSize: 40, color: activeStep >= 0 ? '#8bc34a' : '#ccc' }} />,
      2: <CreditCardIcon style={{ fontSize: 40, color: activeStep >= 1 ? '#8bc34a' : '#ccc' }} />,
      3: <DoneIcon style={{ fontSize: 40, color: activeStep >= 2 ? '#8bc34a' : '#ccc' }} />,
    };

    return icons[String(icon)];
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      BackdropProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          maxWidth: '90vw',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          borderRadius: '10%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography id="modal-title" variant="h5" component="h2" sx={{ fontWeight: 'bold', color: '#000', mb: 2 }}>
          Connect Wallet
        </Typography>
        <Stepper activeStep={activeStep} alternativeLabel sx={{ width: '100%', mb: 4 }}>
          {steps.map((label, index) => (
            <Step key={index}>
              <CustomStepLabel StepIconComponent={StepIcon}>{label}</CustomStepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          {activeStep === steps.length ? (
            <div>
              <Typography sx={{ fontWeight: 'bold', color: '#4caf50', fontSize: '1.2rem' }}>
                All steps completed - you're finished
              </Typography>
              <Typography sx={{ mt: 2, fontSize: '1rem' }}>Connected Address: {connectedAddress}</Typography>
              <Button onClick={handleReset} sx={{ mt: 2 }}>Reset</Button>
            </div>
          ) : (
            <div>
              <Typography sx={{ fontWeight: 'bold', color: '#000', mb: 2, fontSize: '1.2rem' }}>
                {` ${steps[activeStep]}`}
              </Typography>
              {activeStep === 0 && <ConnectEthereum onConnected={handleConnected} />}
              {activeStep === 1 && <ConnectBearby onConnected={handleConnected} />}
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', pt: 4 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1, color: '#8bc34a', fontSize: '1.2rem' }} 
                >
                  Back
                </Button>
                <Button
                  onClick={handleNext}
                  sx={{ color: '#8bc34a', fontSize: '1.2rem' }}
                  disabled={!isStepComplete[activeStep]} 
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Box>
            </div>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ConnectModal;
