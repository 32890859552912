import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "./styles/GlobalStyles";
import { light } from "./styles/Themes";
import Home from "./components/sections/Home";
import About from "./components/sections/About";
import Roadmap from "./components/sections/Roadmap";
import Faq from "./components/sections/Faq";
import ScrollToTop from "./components/ScrollToTop";
import ConnectModal from "./components/connectWallet/ConnectModal";
import Header from "./components/header";
import Footer from "./components/footer/index";


function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLaunchModalOpen, setIsLaunchModalOpen] = useState(true);

  const handleOpenModal = () => {
    console.log("Open Modal");
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    console.log("Close Modal");
    setIsModalOpen(false);
  };

  // const handleLaunchModalClose = () => {
  //   setIsLaunchModalOpen(false);
  // };

  useEffect(() => {
    setIsLaunchModalOpen(true);
  }, []);

  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={light}>
        {/* <Navigation onConnectClick={handleOpenModal} /> */}
        <Header onConnectClick={handleOpenModal}/> 
        <Home />
        <About />
        <Roadmap />
        <Faq />
        <Footer />
        <ScrollToTop />
        <ConnectModal open={isModalOpen} onClose={handleCloseModal} />
        {/* <LaunchModal
          open={isLaunchModalOpen}
          onClose={handleLaunchModalClose}
          link="https://sweepwidget.com/c/82418-qsid5zgb"
          imageUrl={questImage}
        /> */}
      </ThemeProvider>
    </>
  );
}

export default App;