import { createGlobalStyle } from "styled-components";
import "@fontsource/akaya-telivigala";
import "@fontsource/sora";

const GlobalStyles = createGlobalStyle`
    *, *::before, *::after {
        margin: 0;
        padding: 0;
    }

    html,body {
        font-family: 'Sora', sans-serif;
        max-width: 100vw!important;
        overflow-x: hidden!important;
        padding-right: 0 !important;
        margin: 0 !important;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        padding: 0;
    }

    a {
        color: inherit;
        text-decoration: none;
    }
    /* Custom scrollbar */
    ::-webkit-scrollbar {
        width: 3px;
    }

    ::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 4px;
        transition: background-color 0.3s ease;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }
    .rpv-core__viewer .rpv-core__page-layer {
        margin : 0 auto;
    }
`;

export default GlobalStyles;